import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderImage from "../../../components/header/bigHeader/bigHeaderImage"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/dummyvideo.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"

const MyBuschJaegerPortal = function (props, ref) {

    const data = useStaticQuery(graphql`
        query MyBuschJaegerPotalQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-mybuschjaeger/thumbleiste/bje-mybuschjaeger-00.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-mybuschjaeger/thumbleiste/bje-mybuschjaeger-00.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-mybuschjaeger/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Kundenportal"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
            title: "Toolchain",
            objects: ["sketch", "html", "css", "js", "react"],
        },
    ]

    return (
        <Layout>
            <SEO
                title="myBUSCH-JAEGER"
                description="Q:marketing realisiert digitales Ecosystem für Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderImage mode="textFirst" bgCol="#fff" bgImage={data.heropic.childImageSharp.gatsbyImageData}>
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-4">
                        <img src={logo} className="h-40px" alt="" />
                        <h1>myBUSCH-JAEGER</h1>
                        <p className="introtext">Die von uns entwickelte Plattform vereint unterschiedliche digitale Produkt- und Vertriebslösungen. Sie bietet darüber hinaus Kunden von Busch-Jaeger digitale Mehrwerte wie z.B. den Fernzugriff auf Smart Home oder Türkommunikationsanlagen oder die Fernwartung durch qualifizierte Elektroninstallateure.</p>
                        <AnchorLink to="/projekte/ecosystems/mybusch-jaeger-portal#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                </div>
            </BigHeaderImage>

            {/* Bilderstrecke Thumbs */}
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6" />


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "myBUSCH-JAEGER das digitale Ecosystem von Busch-Jaeger. Es kombiniert digitale Dienste und bildet die Basis für digitale Geschäftsprozesse.",
                        "Der persönliche myBUSCH-JAEGER Account ist die Eintrittskarte in die digitale Busch-Jaeger Welt.",
                        "Verbindet Endkunden, Installateuere, Großhandel und Busch-Jaeger.",
                        "Kombiniert verschiedene Tools und Konfiguratoren zur Planung und zum Betrieb von Anlagen unter einem Dach.",
                        "z. B. Busch-Jaeger Excellence Portal, Busch-Jaeger Installer Portal, myBUSCH-JAEGER-Portal, Smart Home Konfigurator, Welcome Konfigurator, 3D LiveView App"
                    ]}
                />
            </ListenModule>


            <Specs data={specsData} />

            {/* navigation */}
            <Navigation location={props.location} />

            {/* kontakt */}
            <Kontakt ansprechpartner="juergen-dworak" />

        </Layout>
    )
}

export default MyBuschJaegerPortal